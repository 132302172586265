import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Spinner, Alert } from "react-bootstrap";
import axios from "../../constents/axios"; // Your configured Axios instance


const UploadReel = ({ show, handleClose, onSuccess }) => {
  const [formState, setFormState] = useState({
    file: null,
    topic: "",
    description: "",
    isFreeReel: false,
    selectedSubject: "",
  });
  const [subjects, setSubjects] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });


  // Reset Form
  const resetForm = () => {
    setFormState({
      file: null,
      topic: "",
      description: "",
      isFreeReel: false,
      selectedSubject: "",
    });
    setMessage({ type: "", text: "" });
  };


  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setMessage({ type: "", text: "" });
  };


  // Handle File Change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormState((prev) => ({ ...prev, file }));
    }
  };


  // Handle Upload
  const handleUpload = async () => {
    const { file, topic, description, isFreeReel, selectedSubject } = formState;
 
    if (!file || !topic || !description || !selectedSubject) {
      setMessage({ type: "error", text: "Please fill in all fields before uploading." });
      return;
    }
 
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", file); // Append the actual file
      formData.append("fileName", file.name); // Ensure file name is sent
      formData.append("fileType", file.type); // Ensure file type is sent
 


      const presignedResponse = await axios.post("/getOraclePresignedUrl", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
 
      const { fileUrl, uploadedFileName } = presignedResponse.data;
 
      // Upload reel metadata to backend
      await axios.post("/createReel", {
        reel: fileUrl,
        fileName: uploadedFileName,
        topic,
        description,
        isFree: isFreeReel,
        subject: selectedSubject,
      });
 
      setMessage({ type: "success", text: "Reel uploaded successfully!" });
      onSuccess();
      resetForm();
      handleClose();
    } catch (err) {
      console.error("Error uploading reel:", err);
      setMessage({ type: "error", text: "Failed to upload reel. Please try again." });
    } finally {
      setIsUploading(false);
    }
  };
 


  // Fetch Subjects
  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const endpoint = formState.isFreeReel ? "/getFreeSubjects" : "/getPremiumSubjects";
        const { data } = await axios.get(endpoint);
        setSubjects(data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
        setSubjects([]);
      }
    };
    fetchSubjects();
  }, [formState.isFreeReel]);


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload New Reel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUploading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
            <span className="ms-2">Uploading...</span>
          </div>
        ) : (
          <>
            {message.text && (
              <Alert variant={message.type === "error" ? "danger" : "success"} className="mt-2">
                {message.text}
              </Alert>
            )}
            <Form>
              <Form.Group className="mt-3">
                <Form.Label>Upload Reel</Form.Label>
                <Form.Control type="file" accept="video/*" onChange={handleFileChange} />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  name="topic"
                  value={formState.topic}
                  onChange={handleInputChange}
                  placeholder="Enter topic"
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={formState.description}
                  onChange={handleInputChange}
                  placeholder="Enter description"
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Check
                  type="checkbox"
                  name="isFreeReel"
                  label="Free Reel"
                  checked={formState.isFreeReel}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Choose Subject</Form.Label>
                <Form.Control
                  as="select"
                  name="selectedSubject"
                  value={formState.selectedSubject}
                  onChange={handleInputChange}
                >
                  <option value="">Select Subject</option>
                  {subjects.map((subject, index) => (
                    <option key={index} value={subject.subject}>
                      {subject.subject}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isUploading}>
          Close
        </Button>
        {!isUploading && (
          <Button variant="primary" onClick={handleUpload}>
            Upload Reel
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};


export default UploadReel;
