import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import styles from "../../../styles/addTest.module.css";
import axios from "../../../constents/axios";
import { useNavigate } from "react-router-dom";

const AddTest = () => {
  const [labels, setLabels] = useState([]);
  const [sections, setSections] = useState([]);
  const [batches, setBatches] = useState([]);
  const [courses, setCourses] = useState([]);

  const [isSectionEnabled, setIsSectionEnabled] = useState(false);
  const [isDefaultSettings, setIsDefaultSettings] = useState(false);
  const [isMoreDetails, setIsMoreDetails] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isOffer, setIsOfferPaid] = useState(true);
  const [isPublishResult, setIsPublishResult] = useState(true);
  const [isPublishDemo, setIsPublishDemo] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [sectionQuestionCount, setSectionQuestionCount] = useState(0);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [batchQuestionCount, setBatchQuestionCount] = useState(0);
  const navigate = useNavigate();
  const location = window.location.pathname.split("/");
  const courseTitle = localStorage.getItem("CourseTitle");
  const storedData = localStorage.getItem("quizData");
  let storedTopic; // Declare the variable outside the if block
  let storedCourse;
  let storedSubTopic;
  
  if (storedData) {
    const storedTopicData = JSON.parse(storedData); // Parse the string into an object
    storedTopic = storedTopicData.topicName; // Assign the value to the variable
    storedCourse = storedTopicData.courseName
    storedSubTopic = storedTopicData.subTopicName
  }
  
  console.log("storedTopic", storedTopic); // Log the variable outside the if block
  
  
  
  const [formData, setFormData] = useState({
    title: "",
    topic: "",
    duration: "",
    valid_from: "",
    startTime: "",
    valid_to: "",
    endTime: "",
    type: null,
    label: null,
    sectionId: "",
    batchId: null,
    paper: null,
    general_instruction: "",
    is_active: isActive,
    is_paid: isOffer,
    isPublishDemo : isPublishDemo === true ? 1 : 0,
    is_result_published : isPublishResult === true ? 1 : 0,
    courses: selectedCourses,
    positive_mark: "",
    negative_mark: "",
    question_attend_limit: "",
  });

  const fetchLabels = async () => {
    try {
      const response = await axios.get("/fetch-label", {
        headers: {
          autherisation: "V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa",
        },
      });
      setLabels(response.data.data);
    } catch (error) {
      console.error("Error fetching labels:", error);
    }
  };
  const fetchCourses = async () => {
    const label = selectedLabel.value;
    try {
      const response = await axios.get(`/courseId/${label}`, {
        headers: {
          autherisation: "V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa",
        },
      });
      setCourses(response.data.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  const fetchSections = async () => {
    try {
      const response = await axios.get('/tests/getSectionIds', {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`, // Replace with your actual token
        },
      });
      setSections(response.data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    } 
  };
  const fetchTotalQuestionsBySection = async () => {
    const sectionId = selectedSection.value;
    try {
      const response = await axios.get(`/tests/getTotalQuestionsBySection/${sectionId}`, {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`, // Replace with your actual token
        },
      });
      setSectionQuestionCount(response.data.total);
    } catch (error) {
      console.error('Error fetching total questions by section:', error);
      return 0;
    } 
  };
  const fetchBatches = async () => {
    try {
      const response = await axios.get('/tests/getBatchIds', {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`, // Replace with your actual token
        },
      });
      setBatches(response.data);
    } catch (error) {
      console.error('Error fetching batches:', error);
    } 
  };
  const fetchTotalQuestionsByBatch = async () => {
    const batchId = selectedBatch.value;
    try {
      const response = await axios.get(`/tests/getTotalQuestionsByBatch/${batchId}`, {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`, // Replace with your actual token
        },
      });
      setBatchQuestionCount(response.data[0].total);
    } catch (error) {
      console.error('Error fetching total questions by batch:', error);
    }
  };
  
  const testTypeOptions = [
    { value: "mt", label: "Mock Test" },
    { value: "dt", label: "Daily Test" },
    { value: "cpt", label: "Categorised Practise Test" },
    { value: "cpyq", label: "Categorised PYQ" },
    { value: "pyq", label: "Previous Year Questions" },
    { value: "mmt", label: "Mega Mock Test" },
    { value: "q", label: "Quiz" },
  ];
  const filteredOptions = location.includes("AddQuiz") ? [{ value: "q", label: "Quiz" }] : testTypeOptions;
  const labelOptions = labels?.map((label) => ({ value: label.label, label: label.label }));
  const sectionOptions = sections?.map((section) => ({ value: section.question_set_id, label: section.question_set_id }));
  const batchOptions = batches?.map((batch) => ({ value: batch.questions_batch_id, label: batch.questions_batch_id }));
  const paperOptions = [
    { value: "Paper I", label: "Paper I" },
    { value: "Paper II", label: "Paper II" },
    { value: "Paper 1", label: "Paper 1" },
    { value: "Paper 2", label: "Paper 2" },
    { value: "Part A", label: "Part A" },
    { value: "Part B", label: "Part B" },
  ];
  const instructionOptions = [
    { value: "", label: "Choose Instruction" },
    { value: "No Instruction", label: "No Instruction" },
    { value: "General Instruction", label: "General Instruction" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleSelectChange = (selectedOption, field) => {
    if (field === "batchId") {
      setSelectedBatch(selectedOption);
    }
    if (field === "sectionId") {
      setSelectedSection(selectedOption);
    }
    if (field === "label") {
      setSelectedLabel(selectedOption);
    }else{
      setFormData((prev) => ({ ...prev, [field]: selectedOption }));
    }
  };
  
  const handleSelectedCoursesChange = (courseId, isChecked) => {
    setSelectedCourses((prev) =>
      isChecked
        ? [...prev, courseId]
        : prev.filter((id) => id !== courseId)
    );
  };

  const handleToggleSection = () => {
    setIsSectionEnabled(!isSectionEnabled);
  };
  console.log("subTopicccc",storedSubTopic);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      courses: location.includes("AddQuiz") ? [storedCourse] : selectedCourses,
      is_active: isActive ? 1 : 0,
      is_paid: isOffer ? 1 : 0,
      isPublishDemo: isPublishDemo ? 1 : 0,
      is_result_published: isPublishResult ? 1 : 0,
      topic : location.includes("AddQuiz") ? storedTopic  : formData.topic,
      subTopic : location.includes("AddQuiz") ? storedSubTopic  : formData.subTopic,
      total_questions: sectionQuestionCount !== 0 ? sectionQuestionCount : batchQuestionCount !== 0 ? batchQuestionCount : 0,
    };
    console.log("subTopic",storedSubTopic);
    
    try {
      const response = await axios.post('/tests/addTest', updatedFormData, {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
        },
      });
      console.log('Test added successfully:', response.data);
      navigate(location.includes("AddQuiz") ? `/studentJourney/${storedCourse}` : '/Tests');
      // Reset form
      setFormData({
        title: "",
        topic: "",
        duration: "",
        valid_from: "",
        startTime: "",
        valid_to: "",
        endTime: "",
        type: null,
        label: null,
        sectionId: null,
        batchId: null,
        paper: null,
        general_instruction: "",
        is_active: isActive,
        is_paid: isOffer,
        isPublishDemo : isPublishDemo === true ? 1 : 0,
        is_result_published : isPublishResult === true ? 1 : 0,
        courses: selectedCourses,
        positive_mark: "",
        negative_mark: "",
        question_attend_limit: "",
      });
    } catch (error) {
      console.error('Error adding test:', error);
      alert('Error adding test. Please try again.');
    }
  };

  useEffect(() => {
    fetchLabels();
    fetchSections();
    fetchBatches();
    if (selectedLabel) {
      fetchCourses();
    }
    if (selectedSection) {
      setSelectedBatch(null);
      setBatchQuestionCount(0);
      fetchTotalQuestionsBySection()
    }
    if (selectedBatch) {
      setSelectedSection(null);
      setSectionQuestionCount(0);
      fetchTotalQuestionsByBatch()
    }
  }, [selectedLabel, selectedSection, selectedBatch]);

  
  return (
    <div className={styles.container}>
      {location.includes("AddQuiz") ? (
        <h3>Add Quiz to {courseTitle}</h3>
      ) : <h3>Add Test</h3> }
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            {/* Enable Section Toggle */}
            <div className={styles.enableBarDiv}>
              <p>Enable Section for this Test:</p>
              <label className={styles.toggleSwitch}>
                <input
                  type="checkbox"
                  checked={isSectionEnabled}
                  onChange={handleToggleSection}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            {/* Section ID */}
            {isSectionEnabled && (
              <Form.Group>
                <Form.Label>Section ID:</Form.Label>
                <Select
                  options={sectionOptions}
                  onChange={(sectionOptions) =>
                    handleSelectChange(sectionOptions, "sectionId")
                  }
                  placeholder="Search Section ID..."
                  isSearchable
                />
              </Form.Group>
            )}

            {/* Title */}
            <Form.Group>
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter title of the test"
                value={formData.title}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Test Type */}
            <Form.Group>
              <Form.Label>Test Type:</Form.Label>
              <Select
                options={filteredOptions}
                onChange={(filteredOptions) =>
                  handleSelectChange(filteredOptions, "testType")
                }
                placeholder="Select Test Type"
                isSearchable
              />
            </Form.Group>

            {/* Batch ID */}
            {!isSectionEnabled && (
              <Form.Group>
                <Form.Label>Batch ID:</Form.Label>
                <Select
                  options={batchOptions}
                  onChange={(batchOptions) =>
                    handleSelectChange(batchOptions, "batchId")
                  }
                  placeholder="Search Batch ID..."
                  isSearchable
                />
              </Form.Group>
            )}
            {sectionQuestionCount ? (
              <Form.Group>
                <Form.Label>Total Questions:</Form.Label>
                <Form.Control
                  type="text"
                  name="totalQuestions"
                  value={sectionQuestionCount}
                  // onChange={handleInputChange}
                  readOnly
                />
              </Form.Group>
            ) : 
            batchQuestionCount ? (
            <Form.Group>
            <Form.Label>Total Questions:</Form.Label>
            <Form.Control
              type="text"
              name="totalQuestions"
              value={batchQuestionCount}
              // onChange={handleInputChange}
              readOnly
            />
          </Form.Group>
            ) : null}
            
            {location.includes("AddQuiz") ? (
              <Form.Group>
              <Form.Label>Topic</Form.Label>
              <Form.Control
                type="text"
                name="topic"
                placeholder="Enter topic"
                value={storedTopic}
                readOnly
                // onChange={handleInputChange}
              />
            </Form.Group>):
(
  <Form.Group>
              <Form.Label>Topic:</Form.Label>
              <Form.Control
                type="text"
                name="topic"
                placeholder="Enter topic"
                value={formData.topic}
                onChange={handleInputChange}
                />
            </Form.Group>
              )
            }

            {location.includes("AddQuiz") ? (
              <Form.Group>
              <Form.Label>Sub Topic</Form.Label>
              <Form.Control
                type="text"
                name="sub_topic"
                placeholder="Enter sub topic"
                value={storedSubTopic === "" ? storedTopic : storedSubTopic}
                readOnly
                // onChange={handleInputChange}
              />
            </Form.Group>):null}

            {/* Paper */}
            <Form.Group>
              <Form.Label>Paper (Categorised Practice Test only):</Form.Label>
              <Select
                options={paperOptions}
                onChange={(paperOptions) =>
                  handleSelectChange(paperOptions, "paper")
                }
                placeholder="Search Paper..."
                isSearchable
              />
            </Form.Group>

            {/* Duration */}
            <Form.Group>
              <Form.Label>Duration:</Form.Label>
              <Form.Control
                type="number"
                name="duration"
                placeholder="Enter test duration"
                value={formData.duration}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Start and End Date/Time */}
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="valid_from"
                    value={formData.valid_from}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Time:</Form.Label>
                  <Form.Control
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="valid_to"
                    value={formData.valid_to}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Time:</Form.Label>
                  <Form.Control
                    type="time"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            </div>
            {/* Label */}
                {location.includes("AddQuiz") ? null :
            <div className="">
            <Form.Group>
              <Form.Label>Label:</Form.Label>
              <Select
                options={labelOptions}
                onChange={(labelOptions) =>
                  handleSelectChange(labelOptions, "label")
                }
                placeholder="Search Label..."
                isSearchable
                />
            </Form.Group>
            {courses?.map((course) => (
  <Form.Check
    key={course.course_id}
    type="checkbox"
    name="courses"
    label={course.title}
    value={course.course_id}
    checked={selectedCourses.includes(course.course_id)}
    onChange={(e) => handleSelectedCoursesChange(course.course_id, e.target.checked)}
  />
))}
                </div>
            }

            <Button type="submit" className="mt-3">
              Submit
            </Button>
          </div>

          {/* Right Column */}
          <div className="col-md-6">
            {/* Default Settings Toggle */}
            <div className={styles.enableBarDiv}>
              <span>Do you want to change default settings? </span>
              <Form.Check
                type="radio"
                label="Yes"
                name="defaultSettings"
                onChange={() =>
                  setIsDefaultSettings(!isDefaultSettings)
                }
              />
              <Form.Check
                type="radio"
                label="No"
                name="defaultSettings"
                defaultChecked
                onChange={() =>
                  setIsDefaultSettings(false)
                }
              />
            </div>

            {/* Default Settings Section */}
            {isDefaultSettings && (
              <>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Active:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="Active"
                    defaultChecked
                    onChange={() =>
                      setIsActive(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="Active"
                    // defaultChecked
                    onChange={() =>
                      setIsActive(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Offer As Paid:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Paid"
                    name="offerPaid"
                    defaultChecked
                    onChange={() =>
                      setIsOfferPaid(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Free"
                    name="offerPaid"
                    // defaultChecked
                    onChange={() =>
                      setIsOfferPaid(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Do you want to publish the result: </Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="publishResult"
                    defaultChecked
                    onChange={() =>
                      setIsPublishResult(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="publishResult"
                    // defaultChecked
                    onChange={() =>
                      setIsPublishResult(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Do you want to publish as demo:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="publishDemo"
                    // defaultChecked
                    onChange={() =>
                      setIsPublishDemo(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="publishDemo"
                    defaultChecked
                    onChange={() =>
                      setIsPublishDemo(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>General Instruction:</Form.Label>
                  <Select
                    options={instructionOptions}
                    onChange={(instructionOptions) =>
                      handleSelectChange(instructionOptions, "general_instruction")
                    }
                    placeholder="Choose Instrunctions..."
                    isSearchable
                  />
                </Form.Group>
              </>
            )}

            <br />
            {/* More Details Toggle */}
            <div className={styles.enableBarDiv}>
              <span>Do you want to add more details? </span>
              <Form.Check
                type="radio"
                label="Yes"
                name="moreDetails"
                onChange={() =>
                  setIsMoreDetails(!isMoreDetails)
                }
              />
              <Form.Check
                type="radio"
                label="No"
                name="moreDetails"
                defaultChecked
                onChange={() =>
                  setIsMoreDetails(false)
                }
              />
            </div>

            {/* More Details Section */}
            {isMoreDetails && (
              <>
                <Form.Group className="mt-3">
                  <Form.Label>Positive Mark:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Positive Mark"
                    name="positive_mark"
                    value={formData.positive_mark}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Negative Mark:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Negative Mark"
                    name="negative_mark"
                    value={formData.negative_mark}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Question Attend Limit:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Limit of Questions to Attend"
                    name="question_attend_limit"
                    value={formData.question_attend_limit}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddTest;
