import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import styles from "../../../styles/addTest.module.css";
import axios from "../../../constents/axios";
import { IoClose } from "react-icons/io5";

const EditTest = () => {
  const [labels, setLabels] = useState([]);
  const [batches, setBatches] = useState([]);
  const [courses, setCourses] = useState([]);
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [showDeleteCourse, setShowDeleteCourse] = useState(false);

  const [isDefaultSettings, setIsDefaultSettings] = useState(false);
  const [isMoreDetails, setIsMoreDetails] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isOffer, setIsOfferPaid] = useState(true);
  const [isPublishResult, setIsPublishResult] = useState(true);
  const [isPublishDemo, setIsPublishDemo] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [sectionQuestionCount, setSectionQuestionCount] = useState(0);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [batchQuestionCount, setBatchQuestionCount] = useState(0);

  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [duration, setDuration] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [startTime, setStartTime] = useState("");
  const [validTo, setValidTo] = useState("");
  const [endTime, setEndTime] = useState("");
  const [type, setType] = useState(null);
  const [paper, setPaper] = useState(null);
  const [generalInstruction, setGeneralInstruction] = useState("");
  const [positiveMark, setPositiveMark] = useState("");
  const [negativeMark, setNegativeMark] = useState("");
  const [questionAttendLimit, setQuestionAttendLimit] = useState("");
  const [selectedDeleteCourse, setSelectedDeleteCourse] = useState(null);
  const [selectedDeleteTestId, setSelectedDeleteTestId] = useState(null);

  const location = window.location.pathname.split("/");
  const courseTitle = localStorage.getItem("CourseTitle");

  const testTypeOptions = [
    { value: "mt", label: "Mock Test" },
    { value: "dt", label: "Daily Test" },
    { value: "cpt", label: "Categorised Practise Test" },
    { value: "cpyq", label: "Categorised PYQ" },
    { value: "pyq", label: "Previous Year Questions" },
    { value: "mmt", label: "Mega Mock Test" },
    { value: "q", label: "Quiz" },
  ];
  const filteredOptions = location.includes("AddQuiz")
    ? [{ value: "q", label: "Quiz" }]
    : testTypeOptions;
  const labelOptions = labels?.map((label) => ({ value: label.label, label: label.label }));
  const batchOptions = batches?.map((batch) => ({ value: batch.questions_batch_id, label: batch.questions_batch_id }));
  const paperOptions = [
    { value: "Paper I", label: "Paper I" },
    { value: "Paper II", label: "Paper II" },
    { value: "Paper 1", label: "Paper 1" },
    { value: "Paper 2", label: "Paper 2" },
    { value: "Part A", label: "Part A" },
    { value: "Part B", label: "Part B" },
  ];
  const instructionOptions = [
    { value: "", label: "Choose Instruction" },
    { value: "No Instruction", label: "No Instruction" },
    { value: "General Instruction", label: "General Instruction" },
  ];


  const fetchCurrentTest = async () => {
    const testId = location[2];
    try {
      const response = await axios.get(`/tests/fetch-test/${testId}`, {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
        },
      });

      console.log("API Response:", response.data);

      const data = response.data[0];
      if (!data) {
        console.error("No data found in the API response.");
        return;
      }

      const validFromDate = new Date(data.valid_from);
      const validToDate = new Date(data.valid_to);
      const formatDate = (date) => date.toISOString().split("T")[0];
      const formatTime = (date) => date.toISOString().split("T")[1].split(".")[0];

      setTitle(data.title || "");
      setTopic(data.topic || "");
      setDuration(data.duration || 0);
      setValidFrom(formatDate(validFromDate));
      setStartTime(formatTime(validFromDate));
      setValidTo(formatDate(validToDate));
      setEndTime(formatTime(validToDate));

      setType(data.type ? { value: data.type, label: getTypeLabel(data.type) } : null);
      setPaper(data.paper ? { value: data.paper, label: data.paper } : null);
      setSelectedBatch(data.questions_batch_id ? { value: data.questions_batch_id, label: data.questions_batch_id } : null);

      setPositiveMark(data.correct_answer_mark || 0);
      setNegativeMark(data.wrong_answer_mark || 0);
      setQuestionAttendLimit(data.question_attend_limit || 0);
      setGeneralInstruction(data.general_instruction || "");
      setIsActive(data.is_active === 1);
      setIsOfferPaid(data.is_paid === 1);
      setIsPublishResult(data.is_result_published === 1);
      setIsPublishDemo(data.isPublishDemo === 1);

    } catch (error) {
      console.error("Error fetching current test:", error);
    }
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case "mt": return "Mock Test";
      case "dt": return "Daily Test";
      case "cpt": return "Categorised Practise Test";
      case "cpyq": return "Categorised PYQ";
      case "pyq": return "Previous Year Questions";
      case "mmt": return "Mega Mock Test";
      default: return "Quiz";
    }
  };

  const fetchLabels = async () => {
    try {
      const response = await axios.get("/fetch-label", {
        headers: {
          autherisation: "V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa",
        },
      });
      setLabels(response.data.data);
    } catch (error) {
      console.error("Error fetching labels:", error);
    }
  };
  const fetchCourses = async () => {
    const label = selectedLabel?.value || "";
    if (!label) {
      console.error("Label is not selected.");
      return;
    }
    try {
      const response = await axios.get(`/courseId/${label}`, {
        headers: {
          autherisation: "V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa",
        },
      });

      setCourses(response.data.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  const fetchBatches = async () => {
    try {
      const response = await axios.get('/tests/getBatchIds', {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`, // Replace with your actual token
        },
      });
      setBatches(response.data);
    } catch (error) {
      console.error('Error fetching batches:', error);
    }
  };
  const fetchTotalQuestionsByBatch = async () => {
    const batchId = selectedBatch.value;
    try {
      const response = await axios.get(`/tests/getTotalQuestionsByBatch/${batchId}`, {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`, // Replace with your actual token
        },
      });

      setBatchQuestionCount(response.data[0].total);
    } catch (error) {
      console.error('Error fetching total questions by batch:', error);
    }
  };
  const fetchTestBaseCourses = async () => {
  
    if (!selectedBatch || !selectedBatch.value) {
      console.error("Error: selectedBatch or selectedBatch.value is null or undefined");
      return;
    }
    if (!type || !type.value) {
      console.error("Error: type or type.value is null or undefined");
      return;
    }
    if (!title) {
      console.error("Error: title is null or undefined");
      return;
    }
  
    try {
      const response = await axios.post(
        `/tests/getTestBaseCourses`,
        {
          batchId: selectedBatch.value,
          type: type.value,
          paper: paper?.value || null,
          title,
        },
        {
          headers: {
            autherisation: `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
          },
        }
      );
      
      setAssignedCourses(response.data.courses || []);
    } catch (error) {
      console.error("Error fetching test base courses:", error);
    }
  };  
  

  const handleSelectChange = (selectedOption, field) => {
    if (field === "batchId") {
      setSelectedBatch(selectedOption);
    }
    if (field === "sectionId") {
      setSelectedSection(selectedOption);
    }
    if (field === "label") {
      setSelectedLabel(selectedOption);
    } else {
    }
  };

  const handleSelectedCoursesChange = (courseId, isChecked) => {
    setSelectedCourses((prev) =>
      isChecked
        ? [...prev, courseId]
        : prev.filter((id) => id !== courseId)
    );
  };
  const handleOpenDeleteModal = (testId, courseId) => {
    setSelectedDeleteCourse(courseId);
    setSelectedDeleteTestId(testId);
    setShowDeleteCourse(true);
  }
  const handleRemoveCourse = async () => {
    const response = await axios.delete(`/tests/removeCourseBaseTest`, {
      data: {
          testId: selectedDeleteTestId,
          courseId: selectedDeleteCourse,
      },
  });
      if (response.status === 200) {
        fetchTestBaseCourses();
        setShowDeleteCourse(false);
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = {
      title,
      topic: topic,
      duration,
      valid_from: `${validFrom} ${startTime}`,
      valid_to: `${validTo} ${endTime}`,
      type: type?.value,
      sectionId: selectedSection?.value,
      batchId: selectedBatch?.value,
      paper: paper?.value,
      general_instruction: generalInstruction,
      is_active: isActive ? 1 : 0,
      is_paid: isOffer ? 1 : 0,
      isPublishDemo: isPublishDemo ? 1 : 0,
      is_result_published: isPublishResult ? 1 : 0,
      courses: selectedCourses,
      assigned_courses: assignedCourses,
      positive_mark: positiveMark,
      negative_mark: negativeMark,
      question_attend_limit: questionAttendLimit,
      total_questions: sectionQuestionCount || batchQuestionCount || 0,
    };
    console.log("updatedData", updatedData);


    try {
      const response = await axios.put("/tests/editTest", updatedData, {
        headers: {
          autherisation: `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
        },
      });
      console.log("Test updated successfully:", response);
      alert("Test updated successfully!");
      // window.location.reload();
    } catch (error) {
      console.error("Error updating test:", error);
    }
  };
  useEffect(() => {
    fetchBatches();
    fetchCurrentTest();
  }, []);



  useEffect(() => {
    fetchLabels();
  }, []);


  useEffect(() => {
    if (selectedLabel) {
      fetchCourses();
    }
  }, [selectedLabel]);

  useEffect(() => {
    if (selectedSection) {
      setSelectedBatch(null);
      setBatchQuestionCount(0);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (selectedBatch) {
      fetchTotalQuestionsByBatch();
    }
  }, [selectedBatch]);

  useEffect(() => {
    if (selectedBatch && type && title){
      fetchTestBaseCourses();
    }

  }, [selectedBatch, type, title]);


  return (
    <div className={styles.container}>
      {location.includes("EditQuiz") ? (
        <h3>Edit Quiz in {courseTitle}</h3>
      ) : <h3>Edit Test</h3>}
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">

            {/* Title */}
            <Form.Group>
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter title of the test"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            {/* Test Type */}
            <Form.Group>
              <Form.Label>Test Type:</Form.Label>
              <Select
                options={filteredOptions}
                value={type}
                onChange={(filteredOptions) =>
                  handleSelectChange(filteredOptions, "testType")
                }
                placeholder="Select Test Type"
                isSearchable
              />
            </Form.Group>


            <Form.Group>
              <Form.Label>Batch ID:</Form.Label>
              <Select
                options={batchOptions}
                value={selectedBatch} // Bind the selected value here
                onChange={(selectedOption) => handleSelectChange(selectedOption, "batchId")}
                placeholder="Search Batch ID..."
                isSearchable
              />
            </Form.Group>



            <Form.Group>
              <Form.Label>Total Questions:</Form.Label>
              <Form.Control
                type="text"
                name="totalQuestions"
                value={batchQuestionCount}
                readOnly
              />
            </Form.Group>


            <Form.Group>
              <Form.Label>Topic:</Form.Label>
              <Form.Control
                type="text"
                name="topic"
                placeholder="Enter topic"
                value={topic}
                readOnly={location.includes("EditQuiz")}
                onChange={(e) => setTopic(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Paper (Categorised Practice Test only):</Form.Label>
              <Select
                options={paperOptions}
                value={paper}
                onChange={(paperOptions) =>
                  handleSelectChange(paperOptions, "paper")
                }
                placeholder="Search Paper..."
                isSearchable
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Duration:</Form.Label>
              <Form.Control
                type="text"
                name="duration"
                placeholder="Enter test duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </Form.Group>

            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="valid_from"
                    value={validFrom}
                    onChange={(e) => setValidFrom(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Time:</Form.Label>
                  <Form.Control
                    type="time"
                    name="startTime"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="valid_to"
                    value={validTo}
                    onChange={(e) => setValidTo(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Time:</Form.Label>
                  <Form.Control
                    type="time"
                    name="endTime"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>

            <Button type="submit" className="mt-3">
              Submit
            </Button>
          </div>

          <div className="col-md-6">
              <div className="">
                <Form.Group>
                  <Form.Label>Label:</Form.Label>
                  <Select
                    options={labelOptions}
                    onChange={(labelOptions) =>
                      handleSelectChange(labelOptions, "label")
                    }
                    placeholder="Search Label..."
                    isSearchable
                  />
                </Form.Group>
                {courses?.map((course) => (
                  <Form.Check
                    key={course.course_id}
                    type="checkbox"
                    name="courses"
                    label={course.title}
                    value={course.course_id}
                    checked={selectedCourses.includes(course.course_id)}
                    onChange={(e) => handleSelectedCoursesChange(course.course_id, e.target.checked)}
                  />
                ))}
              </div>
            {/* {assignedCourses.length > 0 && */}
            <div><h5>Assigned Courses</h5>
              <div>
                {assignedCourses.map((course) => (
                  <p style={{ color: "blue", backgroundColor: "lightblue", padding: "5px", borderRadius: "5px", display: "flex", justifyContent: "space-between" }}>{course.title} <div><Button variant="danger" onClick={() => handleOpenDeleteModal(course.test_id, course.course_id)} className="btn-sm"><IoClose className="fs-5" /></Button></div></p>
                ))}
              </div>
            </div>
              {/* } */}
            <div className={styles.enableBarDiv}>
              <span>Do you want to change default settings? </span>
              <Form.Check
                type="radio"
                label="Yes"
                name="defaultSettings"
                onChange={() =>
                  setIsDefaultSettings(!isDefaultSettings)
                }
              />
              <Form.Check
                type="radio"
                label="No"
                name="defaultSettings"
                defaultChecked
                onChange={() =>
                  setIsDefaultSettings(false)
                }
              />
            </div>

            {isDefaultSettings && (
              <>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Active:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="Active"
                    defaultChecked
                    onChange={() =>
                      setIsActive(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="Active"
                    onChange={() =>
                      setIsActive(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Offer As Paid:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Paid"
                    name="offerPaid"
                    defaultChecked
                    onChange={() =>
                      setIsOfferPaid(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Free"
                    name="offerPaid"
                    onChange={() =>
                      setIsOfferPaid(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Do you want to publish the result: </Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="publishResult"
                    defaultChecked
                    onChange={() =>
                      setIsPublishResult(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="publishResult"
                    onChange={() =>
                      setIsPublishResult(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Do you want to publish as demo:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="publishDemo"
                    onChange={() =>
                      setIsPublishDemo(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="publishDemo"
                    defaultChecked
                    onChange={() =>
                      setIsPublishDemo(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>General Instruction:</Form.Label>
                  <Select
                    options={instructionOptions}
                    onChange={(instructionOptions) =>
                      handleSelectChange(instructionOptions, "general_instruction")
                    }
                    placeholder="Choose Instrunctions..."
                    isSearchable
                  />
                </Form.Group>
              </>
            )}

            <br />
            <div className={styles.enableBarDiv}>
              <span>Do you want to add more details? </span>
              <Form.Check
                type="radio"
                label="Yes"
                name="moreDetails"
                onChange={() =>
                  setIsMoreDetails(!isMoreDetails)
                }
              />
              <Form.Check
                type="radio"
                label="No"
                name="moreDetails"
                defaultChecked
                onChange={() =>
                  setIsMoreDetails(false)
                }
              />
            </div>

            {/* More Details Section */}
            {isMoreDetails && (
              <>
                <Form.Group className="mt-3">
                  <Form.Label>Positive Mark:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Positive Mark"
                    name="positive_mark"
                    value={positiveMark}
                    onChange={(e) => setPositiveMark(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Negative Mark:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Negative Mark"
                    name="negative_mark"
                    value={negativeMark}
                    onChange={(e) => setNegativeMark(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Question Attend Limit:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Limit of Questions to Attend"
                    name="question_attend_limit"
                    value={questionAttendLimit}
                    onChange={(e) => setQuestionAttendLimit(e.target.value)}
                  />
                </Form.Group>
              </>
            )}
          </div>
        </div>
      </Form>
      <Modal size="md" centered show={showDeleteCourse} onHide={() => setShowDeleteCourse(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this test?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handleRemoveCourse}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditTest;
