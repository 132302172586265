import React, { useState, useEffect } from 'react';
import { Button, DropdownButton, Dropdown, Modal, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from '../../../../constents/axios';

const AddMaterial = ({ show, onHide, courseName, currentTopicName, currentSubTopicName, onSuccess }) => {
  const [materialType, setMaterialType] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [error, setError] = useState(null);

  const handleAddMaterial = async (e) => {
    e.preventDefault();
    if (!materialId || !materialType) {
      setError('Both Material ID and Material Type are required.');
      return;
    }
    try {
      const response = await axios.post(`/add-material`, {
        id: 0,
        course_id: courseName,
        topics: currentTopicName,
        subtopic: currentSubTopicName,
        material_type: materialType,
        material_id: materialId,
      },{
        headers: {
            'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
        }
    });

      const newMaterial = {
        id: materialId,
        type: materialType,
        material: response.data.material,
        title: response.data.title,
        link: response.data.link
      };
      onSuccess(newMaterial); 
      setMaterialId('');
      setMaterialType('');
      setError(null);
      onHide(); 
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError('Error adding material: ' + error.message);
      }
    }
  };

  const handleChangeId = (e) => {
    setMaterialId(e.target.value);
  };

  const handleChangeType = (e) => {
    setMaterialType(e.target.value);
  };

  const handleAddNewMaterial = (materialType) => {
    const materialData = {
      courseName,
      currentTopicName,
      currentSubTopicName,
      materialType
    };
    localStorage.setItem('materialData', JSON.stringify(materialData));
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 3000); 
      return () => clearTimeout(timer); 
    }
  }, [error]);

  return (
    <Modal show={show} size="md" backdrop="static" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Material</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Control size="sm" onChange={handleChangeId} required type="number" placeholder="Material ID" value={materialId} />
          <br />
          <Form.Select size="sm" onChange={handleChangeType} required value={materialType}>
            <option value="">Select Material Type</option>
            <option value="pdf">PDF</option>
            <option value="video">Video</option>
            <option value="quiz">Quiz</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddMaterial}>Add Existing Material</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddMaterial;
