import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import axios from '../../constents/axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaClone } from "react-icons/fa6";
import { TbEdit } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import DeleteQuestions from './QuestionActions/DeleteQuestions';
import CloneQuestions from './QuestionActions/CloneQuestions';

const Questions = () => {
  const [batchID, setBatchID] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [questionID, setQuestionID] = useState('');
  const [cloneQuestionIds, setCloneQuestionIds] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        'tests/getQuestions',
        { batchID },
        {
          headers: {
            'Content-Type': 'application/json',
            'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
          },
        }
      );
      const data = response.data;
      setQuestions(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditPage = (id) => {
    navigate(`/EditQuestion/${id}`);
  };
  const openDeleteModal = (id) => {
    setShowDeleteModal(true);
    setQuestionID(id);
  };

  const handleCheckboxChange = (id) => {
    setCloneQuestionIds((prev) =>
      prev.includes(id) ? prev.filter((questionId) => questionId !== id) : [...prev, id]
    );
  };

  const styleImages = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const images = div.querySelectorAll('img');
    images.forEach((img) => {
      img.style.maxWidth = '400px';
      img.style.height = 'auto';
    });
    return div.innerHTML;
  };

  return (
    <div className="mt-3 bg-light p-3 rounded " style={{ width: '100%', height: '90vh', overflowY: 'scroll' }}>
      <div className="d-flex justify-content-between mb-3">
        <h3>Questions</h3>
        <div className='d-flex gap-2'>
        <Link to="/AddQuestion">
        <Button variant="outline-primary">Add Questions</Button>
        </Link>
        <Button variant ="outline-secondary" onClick={() => setShowCloneModal(true)} disabled={cloneQuestionIds.length === 0}><FaClone/></Button>
        </div>
      </div>
      <div>
        <Form className="row">
          <Form.Group className="col-md-3 mb-3" controlId="formBasicEmail">
            <Form.Label>Search Batch ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Batch ID"
              onChange={(e) => setBatchID(e.target.value)}
            />
          </Form.Group>

          <Button
            variant="outline-primary"
            onClick={handleSubmit}
            className="col-md-1 mb-3 mt-4"
          >
            Submit
          </Button>
        </Form>
      </div>
      {/* Scrollable Table Wrapper */}
      <div style={{ overflowX: 'auto', width: '100%' }}>
        <Table bordered hover responsive >
          <thead>
            <tr>
              <th>Q.No</th>
              <th>Q.BatchID</th>
              <th>Question</th>
              <th>Topic</th>
              <th>Subtopic</th>
              <th>
  Actions 
  <Form.Check
    type="checkbox"
    label="Select All"
    checked={cloneQuestionIds.length === questions.length && questions.length > 0} // Check if all are selected
    indeterminate={cloneQuestionIds.length > 0 && cloneQuestionIds.length < questions.length} // Show indeterminate state
    onChange={(e) => {
      if (e.target.checked) {
        setCloneQuestionIds(questions.map((question) => question.id)); // Select all
      } else {
        setCloneQuestionIds([]); // Deselect all
      }
    }}
  />
</th>

              {/* <th>Actions <Form.Check type="checkbox" label="Select All" onChange={(e) => setCloneQuestionIds(e.target.checked ? questions.map((question) => question.id) : [])} /></th> */}
            </tr>
          </thead>
          <tbody>
            {questions.map((question) => (
              <tr key={question.id}>
                <td>{question.question_number}</td>
                <td>{question.questions_batch_id}</td>
                <td dangerouslySetInnerHTML={{ __html: styleImages(question.question) }}></td>
                <td>{question.topic}</td>
                <td>{question.subtopic}</td>
                <td>
                  <div className="d-flex gap-2">
                  <Button variant="outline-warning" onClick={() => handleEditPage(question.id)}><TbEdit /></Button>
                  <Button variant="outline-danger" onClick={() => openDeleteModal(question.id)}><MdDeleteForever /></Button>
                  </div>
                  <div>
                  <Form.Check
                      type="checkbox"
                      id={`checkbox-${question.id}`}
                      label="Select to Clone"
                      onChange={() => handleCheckboxChange(question.id)}
                      checked={cloneQuestionIds.includes(question.id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteQuestions show={showDeleteModal} handleClose={() => setShowDeleteModal(false)}  questionID={questionID}/>
      <CloneQuestions show={showCloneModal} handleClose={() => setShowCloneModal(false)} questionIDs={cloneQuestionIds} currentBatchID={batchID} />
    </div>
  );
};

export default Questions;
