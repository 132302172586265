import React, { useState } from "react";
import { Pagination } from "react-bootstrap";

const CustomPagination = ({ totalPages, currentPage, onPageChange }) => {
  const [pageGroup, setPageGroup] = useState(1); // Track the current group of 25 pages
  const groupSize = 25; // Number of pages per group

  const totalGroups = Math.ceil(totalPages / groupSize); // Total number of groups
  const startPage = (pageGroup - 1) * groupSize + 1; // Start of the current group
  const endPage = Math.min(startPage + groupSize - 1, totalPages); // End of the current group

  const handlePageChange = (page) => {
    onPageChange(page); // Call the parent handler for page change
  };

  const goToPreviousGroup = () => {
    if (pageGroup > 1) {
      setPageGroup(pageGroup - 1);
    }
  };

  const goToNextGroup = () => {
    if (pageGroup < totalGroups) {
      setPageGroup(pageGroup + 1);
    }
  };

  return (
    <Pagination size="sm" className="mt-3">
      {/* Button to go to the previous group */}
      {pageGroup > 1 && (
        <Pagination.Prev onClick={goToPreviousGroup}>
          Previous
        </Pagination.Prev>
      )}

      {/* Render pages for the current group */}
      {Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
        const page = startPage + index;
        return (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        );
      })}

      {/* Button to go to the next group */}
      {pageGroup < totalGroups && (
        <Pagination.Next onClick={goToNextGroup}>
          Next
        </Pagination.Next>
      )}
    </Pagination>
  );
};

export default CustomPagination;
