import React from 'react'
import { Modal } from 'react-bootstrap'
import axios from '../../../constents/axios'

const DeleteTest = ({show,handleClose,id,onSuccess}) => {

    const handleDelete = async () => {
        try {
            const response = await axios.delete(`/tests/deleteTest/${id}`)
            console.log(response)
            handleClose()
            onSuccess(1)
        } catch (error) {
            console.log(error)
        }
    }
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Delete Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure you want to delete this test?</p>
        </Modal.Body>
        <Modal.Footer>
            <button className='btn btn-danger' onClick={handleDelete}>Yes</button>
        </Modal.Footer>
    </Modal>
  )
}

export default DeleteTest