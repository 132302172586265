import React, { useEffect, useState } from "react";
import { Button, Form, Table, Pagination, Spinner } from "react-bootstrap";
import axios from "../../constents/axios";
import CustomPagination from "./CustomPagination";
import { FaClone } from "react-icons/fa6";
import { TbEdit } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DeleteTest from "./TestActions/DeleteTest";

const Tests = () => {
    const [labels, setLabels] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState("");
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [tests, setTests] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [selectedTestId, setSelectedTestId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const isSubmitDisabled = !selectedLabel || !selectedCourse || !selectedType;

    // Fetch labels
    const fetchLabels = async () => {
        try {
            const response = await axios.get("/fetch-label", {
                headers: {
                    autherisation: "V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa",
                },
            });
            setLabels(response.data.data);
        } catch (error) {
            console.error("Error fetching labels:", error);
        }
    };

    // Fetch courses based on selected label
    const fetchCourses = async (labelId) => {
        try {
            const response = await axios.get(`/courseId/${labelId}`, {
                headers: {
                    autherisation: "V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa",
                },
            });
            setCourses(response.data.data);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    // Fetch all tests
    const fetchAllTests = async (currentPage = 1) => {
        setTests([]);
        setIsLoading(true);
        try {
            const response = await axios.get(`/tests/get-all-tests?page=${currentPage}&limit=50`, {
                headers: {
                    autherisation: "V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa",
                },
            });
            const { data, totalPages, currentPage: responsePage } = response.data;
            setIsLoading(false);
            setTests(data);
            setTotalPages(totalPages);
            setPage(responsePage);
        } catch (error) {
            console.error("Error fetching tests:", error);
        }
    };

    // Fetch filtered tests
    const fetchFilteredTests = async (currentPage = 1) => {
        setTests([]);
        setIsLoading(true);
        try {
            const response = await axios.post(
                "/tests/get-test",
                { label: selectedLabel, course: selectedCourse, type: selectedType, page: currentPage, limit: 50 },
                {
                    headers: {
                        autherisation: "V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa",
                    },
                }
            );
            const { data, totalPages, currentPage: responsePage } = response.data;
            setIsLoading(false);
            setTests(data);
            setTotalPages(totalPages);
            setPage(responsePage);
        } catch (error) {
            console.error("Error fetching filtered tests:", error);
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        fetchFilteredTests(1);
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        if (isFormSubmitted) {
            fetchFilteredTests(pageNumber);
        } else {
            fetchAllTests(pageNumber);
        }
    };

    const handleOpenDeleteModal = (testId) => {
        setSelectedTestId(testId);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleEdit = (testId) => {
        navigate(`/EditTest/${testId}`);
    };
    const handleClone = (testId) => {
        navigate(`/CloneTest/${testId}`);
    };

    // Fetch data on component load and when dependencies change
    useEffect(() => {
        fetchLabels();
        fetchAllTests(1);
    }, []);

    useEffect(() => {
        if (selectedLabel) {
            fetchCourses(selectedLabel);
        }
    }, [selectedLabel]);

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h1>Tests</h1>
                <Link to="/AddTest" className="btn btn-primary  m-2">
                {/* <Button variant="primary"> */}
                    Add Test
                    {/* </Button> */}
                </Link>
            </div>
            <Form onSubmit={handleSubmit} className="row">
                <Form.Group className="col-md-3 mb-3">
                    <Form.Label>Label</Form.Label>
                    <Form.Control as="select" onChange={(e) => setSelectedLabel(e.target.value)} value={selectedLabel}>
                        <option value="">Select Label</option>
                        {labels.map((label) => (
                            <option key={label.id} value={label.id}>
                                {label.label}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group className="col-md-3 mb-3">
                    <Form.Label>Course</Form.Label>
                    <Form.Control as="select" onChange={(e) => setSelectedCourse(e.target.value)} value={selectedCourse}>
                        <option value="">Select Course</option>
                        {courses.map((course) => (
                            <option key={course.course_id} value={course.course_id}>
                                {course.title}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group className="col-md-3 mb-3">
                    <Form.Label>Type</Form.Label>
                    <Form.Control as="select" onChange={(e) => setSelectedType(e.target.value)} value={selectedType}>
                        <option value="">Select Type</option>
                        <option value="mt">Mock Test</option>
                        <option value="dt">Daily Test</option>
                        <option value="cpt">Categorised Practise Test</option>
                        <option value="cpyq">Categorised PYQ</option>
                        <option value="pyq">Previous Year Questions</option>
                        <option value="mmt">Mega Mock Test</option>
                        <option value="q">Quiz</option>
                    </Form.Control>
                </Form.Group>
                <div className="col-md-3 pt-4">
                    <Button className="mt-2 w-50" variant="primary" type="submit" disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </div>
            </Form>
            <div className="table-container" style={{ overflowX: "auto", whiteSpace: "nowrap", height: "70vh" }}>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>SL No</th>
                            <th>Qn batch ID</th>
                            <th>Qn Set ID</th>
                            <th>No of Qns</th>
                            <th>Topic</th>
                            <th>Test Title</th>
                            <th>Type</th>
                            <th>Course</th>
                            <th>Paper</th>
                            <th>Active</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr >
                                <td colSpan="11" className="text-center">
                                    <Spinner animation="border" role="status" />
                                </td>
                            </tr>
                        ) : null}
                        {tests.length === 0 ? (
                            <tr >
                                <td colSpan="11" className="text-center">
                                    <span>No tests found</span>
                                </td>
                            </tr>
                        ) : null}
                        {tests.map((test, index) => (
                            <tr key={test.id}>
                                <td>{(page - 1) * 50 + (index + 1)}</td>
                                <td>{test.questions_batch_id}</td>
                                <td>{test.question_set_id}</td>
                                <td>{test.total_questions}</td>
                                <td style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>{test.topic}</td>
                                <td style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>{test.title}</td>
                                <td>{test.type}</td>
                                <td style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>{test.course}</td>
                                <td>{test.paper}</td>
                                <td>{test.is_active ? "Yes" : "No"}</td>
                                <td className="d-flex gap-2">
                                    <Button variant="outline-warning" onClick={() => handleEdit(test.id)}>
                                        <TbEdit />
                                    </Button>
                                    <Button variant="outline-secondary" onClick={() => handleClone(test.id)}>
                                        <FaClone />
                                    </Button>
                                    <Button variant="outline-danger" onClick={() => handleOpenDeleteModal(test.id)}>
                                        <MdDeleteForever />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CustomPagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
                </div>
            </div>
            <DeleteTest show={showDeleteModal} handleClose={handleCloseDeleteModal} id={selectedTestId} onSuccess={fetchAllTests} />
        </div>
    );
};

export default Tests;
