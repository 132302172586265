import React from 'react'
import { Modal } from 'react-bootstrap'
import axios from '../../../constents/axios'

const DeleteQuestions = ({ show, handleClose, questionID }) => {
  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/tests/deleteQuestion/${questionID}`)
      console.log(response)
      handleClose()
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Delete Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure you want to delete this question?</p>
        </Modal.Body>
        <Modal.Footer>
            <button className='btn btn-danger' onClick={handleDelete}>Yes</button>
        </Modal.Footer>
    </Modal>
  )
}

export default DeleteQuestions