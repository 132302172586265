import React, { useState } from 'react';
import { Button, Modal, Spinner, Alert } from 'react-bootstrap';
import axios from '../../constents/axios';

const DeleteReel = ({ show, handleClose, reel, onDeleteSuccess }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const handleDelete = async () => {
    if (!reel) {
      setMessage({ type: 'error', text: 'Reel not found.' });
      return;
    }

    setIsDeleting(true);
    setMessage({ type: '', text: '' });

    try {
      //Delete the reel from oracle
      const oracleResponse = await axios.delete(`/deleteOracleFile/${reel.fileName}`);
      if(oracleResponse.status !== 200) {
        throw new Error('Failed to delete reel from oracle');
      }else {
        // Only delete the reel record from the database
        await axios.delete(`/deleteReel/${reel._id}`);

          setMessage({ type: 'success', text: 'Reel deleted successfully!' });
        onDeleteSuccess();
        handleClose();
      }
    } catch (err) {
      console.error('Error deleting reel:', err);
      setMessage({ type: 'error', text: 'Failed to delete the reel. Please try again.' });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Reel</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isDeleting ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
            <span className="ms-2">Deleting...</span>
          </div>
        ) : (
          <>
            {/* {message.text && (
              <Alert
                variant={message.type === 'error' ? 'danger' : 'success'}
                className="mt-2"
              >
                {message.text}
              </Alert>
            )} */}
            <p>Are you sure you want to delete this reel? This action cannot be undone.</p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isDeleting}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteReel;
