import React from 'react'
import { Modal } from 'react-bootstrap'
import axios from '../../../constents/axios'

const DeleteSection = ({ show, onHide, onSuccess, sectionId }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`/tests/deleteSection/${sectionId}`, {
        headers: {
           'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`
        }
      });
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error deleting section:', error);
    }
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Section</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this section?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-danger' onClick={handleDelete}>Delete</button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteSection