import React, { useEffect, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import axios from '../../../constents/axios'
import { useLocation, useNavigate } from 'react-router-dom';

const EditQuestion = () => {
    const location = useLocation();
    const [batchId, setBatchID] = useState("");
    const [questionNo, setQuestionNo] = useState("");
    const [question, setQuestion] = useState("");
    const [option1, setOption1] = useState("");
    const [option2, setOption2] = useState("");
    const [option3, setOption3] = useState("");
    const [option4, setOption4] = useState("");
    const [rightAnswer, setRightAnswer] = useState("");
    const [explanation, setExplanation] = useState("");
    const [topic, setTopic] = useState("");
    const [subTopic, setSubTopic] = useState("");
    const [successMessage, setSuccessMessage] = useState(false); // State for success message
    const navigate = useNavigate();

    const extractQuestionId = (path) => {
        const parts = path.split('/');
        return decodeURIComponent(parts[parts.length - 1]);
    };
    const questionId = extractQuestionId(location.pathname);

    const fetchById = async (questionId) => {
        try{
            const response = await axios.get(`/tests/getQuestionById/${questionId}`, {
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            console.log("response",response);
            
            const data = response.data;
            setBatchID(data[0].questions_batch_id);
            setQuestionNo(data[0].question_number);
            setQuestion(data[0].question);
            setOption1(data[0].option_1);
            setOption2(data[0].option_2);
            setOption3(data[0].option_3);
            setOption4(data[0].option_4);
            setRightAnswer(data[0].right_answer);
            setExplanation(data[0].explenation);
            setTopic(data[0].topic);
            setSubTopic(data[0].subtopic);
        } catch (error) {
            console.error('Error fetching question by ID:', error);
        }
    }

    const updateQuestions = async () => {
        try {
            const response = await axios.put(`/tests/editQuestion/${questionId}`, {
                batchId,
                questionNo,
                question,
                option1,
                option2,
                option3,
                option4,
                rightAnswer,
                explanation,
                topic,
                subTopic,
            }, {
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            console.log("edited",response);
            
            setSuccessMessage(true); // Show success message
            setTimeout(() => setSuccessMessage(false), 3000); // Hide after 3 seconds
            navigate('/Questions');
        } catch (error) {
            console.error('Error uploading questions:', error);
        }
    };

    useEffect(() => {
        fetchById(questionId);
    }, [questionId]);


    const styleImages = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const images = div.querySelectorAll('img');
        images.forEach((img) => {
          img.style.maxWidth = '600px'; // Adjust the size here
          img.style.height = 'auto';
        });
        return div.innerHTML;
      };

  return (
    <>
    <div>
        <div className='row'>
            <h3 className='col-md-3'>EditQuestion</h3>
            {successMessage && (
                    <Alert className='col-md-5 text-center' variant="success">Successfully Updated</Alert>
             )}
        </div>
        <div className='row d-flex'>
            <div className="col-md-6" style={{ height: '80vh', overflowY: 'scroll', scrollbarWidth: 'none' }}>
            <Form onSubmit={(e) => {
                            e.preventDefault();
                            updateQuestions();
                        }}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Batch ID</Form.Label>
                            <Form.Control type="text" required value={batchId} onChange={(e) => setBatchID(e.target.value)} placeholder="Enter Batch ID" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Question No:</Form.Label>
                            <Form.Control type="number" value={questionNo} required onChange={(e) => setQuestionNo(e.target.value)} placeholder="Enter Question No" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Question:</Form.Label>
                            <Form.Control as="textarea" value={question} required onChange={(e) => setQuestion(e.target.value)} rows={3}  placeholder="Enter Question" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Options 1:</Form.Label>
                            <Form.Control as="textarea" value={option1} required rows={3} onChange={(e) => setOption1(e.target.value)} placeholder="Enter Option 1"  />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Options 2:</Form.Label>
                            <Form.Control as="textarea" value={option2} required rows={3} onChange={(e) => setOption2(e.target.value)} placeholder="Enter Option 2" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Options 3:</Form.Label>
                            <Form.Control as="textarea" value={option3} required rows={3} onChange={(e) => setOption3(e.target.value)} placeholder="Enter Option 3" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Options 4:</Form.Label>
                            <Form.Control as="textarea" value={option4} required rows={3} onChange={(e) => setOption4(e.target.value)} placeholder="Enter Option 4" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Explanation:</Form.Label>
                            <Form.Control as="textarea" value={explanation} required rows={3} onChange={(e) => setExplanation(e.target.value)} placeholder="Enter Explanation" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Right Answer:</Form.Label>
                            <Form.Control as="select" value={rightAnswer} required onChange={(e) => setRightAnswer(e.target.value)} placeholder="Enter Answer" >
                                <option value="">Select Right Answer</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Topic:</Form.Label>
                            <Form.Control type="text" value={topic} required onChange={(e) => setTopic(e.target.value)} placeholder="Enter Topic" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>SubTopic:</Form.Label>
                            <Form.Control type="text" value={subTopic} required onChange={(e) => setSubTopic(e.target.value)} placeholder="Enter SubTopic" />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Upload
                        </Button>
                    `</Form>
            </div>
            <div className="col-md-6" style={{ height: '80vh', overflowY: 'scroll', scrollbarWidth: 'none', border: "2px solid black" }}>
                <h4 className='text-center'>Preview</h4>
                <div className="preview-content">
                    <strong>Question</strong>
                    <p dangerouslySetInnerHTML={{ __html: styleImages(question) }}></p>
                </div>
                <div className="preview-content">
                    <strong>Option1</strong>
                    <p dangerouslySetInnerHTML={{ __html: styleImages(option1) }}></p>
                </div>
                <div className="preview-content">
                    <strong>Option2</strong>
                    <p dangerouslySetInnerHTML={{ __html: styleImages(option2) }}></p>
                </div>
                <div className="preview-content">
                    <strong>Option3</strong>
                    <p dangerouslySetInnerHTML={{ __html: styleImages(option3) }}></p>
                </div>
                <div className="preview-content">
                    <strong>Option4</strong>
                    <p dangerouslySetInnerHTML={{ __html: styleImages(option4) }}></p>
                </div>
                <div className="preview-content">
                    <strong>Explanation</strong>
                    <p dangerouslySetInnerHTML={{ __html: styleImages(explanation) }}></p>
                </div>
            </div>
        </div>
    </div>
    <style jsx={`
        .preview-content img {
  max-width: 300px;
  height: auto;
}

        `}>

    </style>
    </>
  )
}

export default EditQuestion