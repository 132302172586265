import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Sidebar from './Components/Sidebar/Sidebar';
import Courses from './Components/StudentJourney/Courses';
import AddNewPDF from './Components/StudentJourney/Options/AddDatas/AddNewPDF';
import AddNewVideo from './Components/StudentJourney/Options/AddDatas/AddNewVideo';
import StudentJourney from './Components/StudentJourney/StudentJourney';
import Login from './Components/Login/Login';
import AddLiveVideo from './Components/LiveVideo/AddLiveVideo';
import SubjectforLivevideo from './Components/LiveVideo/SubjectforLivevideo';
import EditLiveVideo from './Components/LiveVideo/EditLiveVideo';
import {jwtDecode} from 'jwt-decode';
import EBooks from './Components/E-Books/E-Books';
import NotFound from './Components/NotFound';
import ConvertStudentJourney from './Components/ConvertStudentJourney/ConvertStudentJourney';
import Home from './Components/Home/Home';
import Reels from './Components/Reels/Reels';
import AddTest from './Components/Tests/TestActions/AddTest';
import Section from './Components/Tests/Section';
import Questions from './Components/Tests/Questions';
import AddQuestions from './Components/Tests/QuestionActions/AddQuestions';
import EditQuestion from './Components/Tests/QuestionActions/EditQuestion';
import Tests from './Components/Tests/Tests';
import EditTest from './Components/Tests/TestActions/EditTest';
import CloneTest from './Components/Tests/TestActions/CloneTest';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const handleLoginSuccess = (userData) => {
    setIsLoggedIn(true);
    setUser(userData);
  };

  useEffect(() => {
    const token = localStorage.getItem('adminLogin');
    if (token) {
      setIsLoggedIn(true);
      const decodedToken = jwtDecode(token); 
      setUser(decodedToken);
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <div className='row'>
          <div className='col-auto' style={{ position: "static", display: "flex", overflow: "hidden" }}>
            <Sidebar user={user} setUser={setUser} />
          </div>
          <div className="col py-3">
            {!isLoggedIn ? <Login onLoginSuccess={handleLoginSuccess} /> : (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/studentJourney" element={<Courses />} />
                <Route path="/studentJourney/:courseName" element={<StudentJourney />} />
                <Route path='/convertStudentJourney' element={<ConvertStudentJourney />} />
                <Route path='/addMaterialPDF' element={<AddNewPDF />} />
                <Route path='/addMaterialVideo' element={<AddNewVideo />} />
                <Route path="/AddLiveVideo" element={<AddLiveVideo />} />
                <Route path='/reels' element={<Reels/>}/>
            <Route path="/LiveVideo" element={<SubjectforLivevideo />} />
            <Route path="/UpdateLiveVideo/:id" element={<EditLiveVideo />} />
            <Route path='/e-books' element={<EBooks />} />
            ////////////////// Section //////////////////////////
            <Route path='/Section' element={<Section />} />
            <Route path='/Questions' element={<Questions />} />
            <Route path='/AddQuestion' element={<AddQuestions/>}/>
            <Route path='/EditQuestion/:questionID' element={<EditQuestion/>}/>
            <Route path='/Tests' element={<Tests />} />
            <Route path='/AddTest' element={<AddTest/>} />
            <Route path='/EditTest/:testID' element={<EditTest/>} />
            <Route path='/CloneTest/:testID' element={<CloneTest/>} />
            <Route path='/AddQuiz' element={<AddTest/>} />
            <Route path='/EditQuiz/:testID' element={<EditTest/>} />
            <Route path='/CloneQuiz/:testID' element={<CloneTest/>} />
            <Route path='*' element={<NotFound />} />
              </Routes>
            )}
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
