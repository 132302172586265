import React, { useEffect, useState } from 'react';
import styles from "../../styles/reels.module.css";
import { Button, Table, Form, Spinner } from 'react-bootstrap';
import axios from '../../constents/axios';
import UploadReel from './UploadReel';
import DeleteReel from './DeleteReel';
import EditReel from './EditReel';

const Reels = () => {
  const [reels, setReels] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedReel, setSelectedReel] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null); // Free/Paid filter
  const [searchTerm, setSearchTerm] = useState(''); // Subject search filter
  const [loading, setLoading] = useState(false); // Loading state

  const fetchReels = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/getReels');
      setReels(response.data);
    } catch (error) {
      console.error("Error fetching reels:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReels();
  }, []);

  const handleDelete = (reel) => {
    setSelectedReel(reel);
    setDeleteModal(true);
  };

  const handleEdit = (reel) => {
    setSelectedReel(reel);
    setEditModal(true);
  };

  const handleShow = () => setShowModal(true);

  const handleClose = () => {
    setShowModal(false);
    fetchReels();
  };

  const formatDateTime = (date) => {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const formattedTime = dateObj.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return { formattedDate, formattedTime };
  };

  const handleCategoryClick = (category) => {
    // Toggle filter for the clicked category
    setFilterCategory(category === 'All' ? null : category);
  };

  // Filter reels based on the selected category and search term
  const filteredReels = reels.filter((reel) => {
    const matchesCategory =
      filterCategory === null || reel.isFree === (filterCategory === 'Free');
    const matchesSearch =
      reel.subject && reel.subject.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });
  

  return (
    <div className={styles.reelPage}>
      <div className={styles.reelHeadDiv}>
        <h1 className={styles.reelHead}>Reels</h1>
        <Button className={styles.addReelBtn} onClick={handleShow}>
          Add New Reel
        </Button>
      </div>
      <div className='row' style={{ marginBottom: '10px' }}>
        <div className={`col-md-6 ${styles.searchBar}`}>
          <Form.Control
            type="text"
            placeholder="Search by subject..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className='col-md-6'>

        <div className={`${styles.categoryFilters}`}>
          <button
            style={{
              marginRight: '10px',
              backgroundColor: filterCategory === 'Free' ? '#28a745' : '',
              color: filterCategory === 'Free' ? '#fff' : '',
            }}
            className={styles.freeButton}
            onClick={() => handleCategoryClick('Free')}
            >
            Free
          </button>
          <button
            style={{
              marginRight: '10px',
              backgroundColor: filterCategory === 'Paid' ? '#dc3545' : '',
              color: filterCategory === 'Paid' ? '#fff' : '',
            }}
            className={styles.paidButton}
            onClick={() => handleCategoryClick('Paid')}
            >
            Paid
          </button>
          <button
            style={{
              backgroundColor: filterCategory === null ? '#007bff' : '',
              color: filterCategory === null ? '#fff' : '',
            }}
            className={styles.allButton}
            onClick={() => handleCategoryClick('All')}
            >
            All
          </button>
            </div>
        </div>
      </div>

      {/* Loading Spinner */}
      {loading ? (
        <div className={styles.loading}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div
          className={styles.reelTable}
          style={{ maxHeight: '75vh', overflowY: 'auto' }}
        >
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Reel path</th>
                <th>Topic</th>
                <th>Description</th>
                <th>Subject</th>
                <th>Category</th>
                <th>Outed Date</th>
                <th>Outed Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredReels.length === 0 ? (
                <tr>
                  <td colSpan="9" style={{ textAlign: 'center' }}>
                    No reels found.
                  </td>
                </tr>
              ) : (
                filteredReels.map((reel, index) => {
                  const { formattedDate, formattedTime } = formatDateTime(reel.date);
                  return (
                    <tr key={reel._id} className={styles.reelRow}>
                      <td>{index + 1}</td>
                      <td style={{ maxWidth: '300px', wordWrap: 'break-word' }}>
                        <a
                          href={reel.reel}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label={`Open reel: ${reel.topic}`}
                        >
                          {reel.reel}
                        </a>
                      </td>
                      <td>{reel.topic}</td>
                      <td>{reel.description}</td>
                      <td>{reel.subject}</td>
                      <td>
                        <div
                          className={reel.isFree ? styles.freeTag : styles.paidTag}
                        >
                          {Boolean(reel.isFree) ? 'Free' : 'Paid'}
                        </div>
                      </td>
                      <td>{formattedDate}</td>
                      <td>{formattedTime}</td>
                      <td className='d-flex gap-2'>
                        <Button
                          variant="outline-danger"
                          onClick={() => handleDelete(reel)}
                          className={styles.deleteBtn}
                          aria-label={`Delete ${reel.topic}`}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="outline-warning"
                          onClick={() => handleEdit(reel)}
                          className={styles.editBtn}
                          aria-label={`Edit ${reel.topic}`}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      )}

      <UploadReel show={showModal} handleClose={handleClose} onSuccess={fetchReels} />
      <DeleteReel
        show={deleteModal}
        handleClose={() => setDeleteModal(false)}
        reel={selectedReel}
        onDeleteSuccess={fetchReels}
      />
      <EditReel
        show={editModal}
        handleClose={() => setEditModal(false)}
        reel={selectedReel}
        onSuccess={fetchReels}
      />
    </div>
  );
};

export default Reels;
