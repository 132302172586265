import React, { useEffect, useState } from 'react';
import axios from '../../../constents/axios';
import { Alert, Button, Form, Table } from 'react-bootstrap';

const AddQuestions = () => {
    const [questions, setQuestions] = useState([]);
    const [batchId, setBatchID] = useState("");
    const [questionNo, setQuestionNo] = useState("");
    const [question, setQuestion] = useState("");
    const [option1, setOption1] = useState("");
    const [option2, setOption2] = useState("");
    const [option3, setOption3] = useState("");
    const [option4, setOption4] = useState("");
    const [rightAnswer, setRightAnswer] = useState("");
    const [explanation, setExplanation] = useState("");
    const [topic, setTopic] = useState("");
    const [subTopic, setSubTopic] = useState("");
    const [successMessage, setSuccessMessage] = useState(false); // State for success message

    const fetchLatestQuestions = async (batchId = "") => {
        try {
            const response = await axios.get(`/tests/getLatestQuestion/${batchId}`, {
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                },
            });
            setQuestions(response.data);
        } catch (error) {
            console.error('Error fetching latest questions:', error);
        }
    };

    useEffect(() => {
        fetchLatestQuestions();
    }, []);

    useEffect(() => {
        if (batchId.trim()) {
            fetchLatestQuestions(batchId);
        }
    }, [batchId]);

    const uploadQuestions = async () => {
        try {
            const response = await axios.post('/tests/addQuestion', {
                batchId,
                questionNo,
                question,
                option1,
                option2,
                option3,
                option4,
                rightAnswer,
                explanation,
                topic,
                subTopic,
            }, {
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            fetchLatestQuestions(batchId);
            setSuccessMessage(true); // Show success message
            setTimeout(() => setSuccessMessage(false), 3000); // Hide after 3 seconds
            setBatchID("");
            setQuestionNo("");
            setQuestion("");
            setOption1("");
            setOption2("");
            setOption3("");
            setOption4("");
            setRightAnswer("");
            setExplanation("");
            setTopic("");
            setSubTopic("");
        } catch (error) {
            console.error('Error uploading questions:', error);
        }
    };

    const styleImages = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const images = div.querySelectorAll('img');
        images.forEach((img) => {
            img.style.maxWidth = '300px';
            img.style.height = 'auto';
        });
        return div.innerHTML;
    };

  return (
    <div>
        <div className='row'>

        <h3 className='col-md-3'>Add Questions</h3>
            {successMessage && (
                    <Alert className='col-md-5 text-center' variant="success">Successfully Added</Alert>
             )}
        </div>
        <div className='row'>
            <div className='col-md-6'>
                <h5>Upload Questions</h5>
                <div style={{ width: '100%', height: '80vh', overflowY: 'scroll', scrollbarWidth: 'none' }}>
                    <Form onSubmit={(e) => {
                            e.preventDefault();
                            uploadQuestions();
                        }}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Batch ID</Form.Label>
                            <Form.Control type="text" required value={batchId} onChange={(e) => setBatchID(e.target.value)} placeholder="Enter Batch ID" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Question No:</Form.Label>
                            <Form.Control type="number" value={questionNo} required onChange={(e) => setQuestionNo(e.target.value)} placeholder="Enter Question No" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Question:</Form.Label>
                            <Form.Control as="textarea" value={question} required onChange={(e) => setQuestion(e.target.value)} rows={3}  placeholder="Enter Question" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Options 1:</Form.Label>
                            <Form.Control as="textarea" value={option1} required rows={3} onChange={(e) => setOption1(e.target.value)} placeholder="Enter Option 1"  />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Options 2:</Form.Label>
                            <Form.Control as="textarea" value={option2} required rows={3} onChange={(e) => setOption2(e.target.value)} placeholder="Enter Option 2" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Options 3:</Form.Label>
                            <Form.Control as="textarea" value={option3} required rows={3} onChange={(e) => setOption3(e.target.value)} placeholder="Enter Option 3" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Options 4:</Form.Label>
                            <Form.Control as="textarea" value={option4} required rows={3} onChange={(e) => setOption4(e.target.value)} placeholder="Enter Option 4" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Explanation:</Form.Label>
                            <Form.Control as="textarea" value={explanation} required rows={3} onChange={(e) => setExplanation(e.target.value)} placeholder="Enter Explanation" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Right Answer:</Form.Label>
                            <Form.Control as="select" value={rightAnswer} required onChange={(e) => setRightAnswer(e.target.value)} placeholder="Enter Answer" >
                                <option value="">Select Right Answer</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Topic:</Form.Label>
                            <Form.Control type="text" value={topic} required onChange={(e) => setTopic(e.target.value)} placeholder="Enter Topic" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>SubTopic:</Form.Label>
                            <Form.Control type="text" value={subTopic} required onChange={(e) => setSubTopic(e.target.value)} placeholder="Enter SubTopic" />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Upload
                        </Button>
                    `</Form>
                </div>
            </div>
            <div className='col-md-6'>
                <h5>Latest Questions</h5>
                <div style={{ width: '100%', height: '80vh', overflowY: 'scroll', scrollbarWidth: 'none' }}>
                <Table bordered hover >
                    <thead>
                        <tr>
                            <th>Q.No</th>
                            <th>Question</th>
                            <th>Topic</th>
                            <th>SubTopic</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions.map((question, index) => (
                            <tr key={index}>
                                <td>{question.question_number}</td>
                                <td dangerouslySetInnerHTML={{ __html: styleImages(question.question) }}></td>
                                <td>{question.topic}</td>
                                <td>{question.subtopic}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddQuestions