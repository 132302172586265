import React, { useState } from 'react';
import { Form, Modal, Alert } from 'react-bootstrap';
import axios from '../../../constents/axios';

const CloneQuestions = ({ show, handleClose, questionIDs, currentBatchID }) => {
    const [newBatchId, setNewBatchId] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleClone = async () => {
        if (!newBatchId) {
            setMessage('Please enter a valid Batch ID.');
            return;
        }

        setIsLoading(true);
        setMessage('');
        try {
            const response = await axios.post(
                '/tests/cloneQuestions',
                { questionIDs, currentBatchID, newBatchId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                    },
                }
            );

            if (response.data) {
                setMessage('Questions cloned successfully!');
                setNewBatchId('');
                window.location.reload();
            } else {
                setMessage(response.data.message || 'Failed to clone questions.');
            }
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Clone Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && <Alert variant="info">{message}</Alert>}
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicBatchId">
                        <Form.Label>New Batch ID</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Batch ID"
                            value={newBatchId}
                            onChange={(e) => setNewBatchId(e.target.value)}
                            disabled={isLoading}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-outline-primary"
                    onClick={handleClone}
                    disabled={isLoading}
                >
                    {isLoading ? 'Cloning...' : 'Clone'}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CloneQuestions;
