import React, { useEffect, useState, useRef } from 'react';
import { Button, Table, Pagination } from 'react-bootstrap';
import axios from '../../constents/axios';
import CreateSection from './SectionActions/CreateSection';
import EditSection from './SectionActions/EditSection';
import DeleteSection from './SectionActions/DeleteSection';

const Section = () => {
  const [sections, setSections] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25; // Number of items per page
  const scrollableDivRef = useRef(null); // Ref for the scrollable container
  const [showCreateSectionModal, setShowCreateSectionModal] = useState(false);
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showSeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const [sectionId, setSectionId] = useState('');

  const fetchSections = async () => {
    try {
      const response = await axios.get('/tests/getSections', {
        headers: {
          "autherisation": `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`, // Replace with your actual token
        },
      });
      setSections(response.data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };
  useEffect(() => {
    fetchSections();
  }, []);

  const handleEditModal = (sectionId) => {
    setSectionId(sectionId);
    setShowEditSectionModal(true);
  };

  const handleDeleteModal = (sectionId) => {
    setSectionId(sectionId);
    setShowDeleteSectionModal(true);
  };

  // Calculate the current page's items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSections = sections.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll the scrollable container to the top
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Generate pagination items
  const totalPages = Math.ceil(sections.length / itemsPerPage);
  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  return (
    <div
      ref={scrollableDivRef} // Attach the ref to the scrollable container
      className="bg-light p-3 rounded shadow m-3 w-100"
      style={{ height: "90vh", overflowY: "scroll" }}
    >
      <div className="d-flex justify-content-between mb-3">
        <h3>Section</h3>
        <Button variant="outline-primary" onClick={() => setShowCreateSectionModal(true)}>Create New Section</Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SL</th>
            <th>QN SET ID</th>
            <th>TITLE</th>
            <th>BATCH ID</th>
            <th>DURATION</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {currentSections.map((section, index) => (
            <tr key={section.id}>
              <td>{indexOfFirstItem + index + 1}</td>
              <td>{section.question_set_id}</td>
              <td>{section.title}</td>
              <td>{section.questions_batch_id}</td>
              <td>{section.duration}</td>
              <td className="d-flex gap-2">
                <Button variant="outline-primary" onClick={() => handleEditModal(section.id)}>Edit</Button>
                <Button variant="outline-danger" onClick={() => handleDeleteModal(section.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination className="justify-content-center mt-3">{paginationItems}</Pagination>
      <CreateSection show={showCreateSectionModal} handleClose={() => setShowCreateSectionModal(false)} onSuccess={fetchSections} />
      <EditSection show={showEditSectionModal} handleClose={() => setShowEditSectionModal(false)} onSuccess={fetchSections} sectionId={sectionId} />
      <DeleteSection show={showSeleteSectionModal} onHide={() => setShowDeleteSectionModal(false)} onSuccess={fetchSections} sectionId={sectionId} />
    </div>
  );
};

export default Section;
